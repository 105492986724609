export const dataSource = [
  {
    key: "8",
    name: "谭娜",
    age: 27,
    address: "Taoyuan St, 绵阳市, 四川省",
    phone: "0816 610 8797",
    status: 0,
  },
  {
    key: "9",
    name: "余鑫",
    age: 23,
    address: "120 Shuguang Rd, 西湖区, 浙江省",
    phone: "0571 8511 5795",
    status: 0,
  },
  {
    key: "1",
    name: "申春梅",
    age: 32,
    address: "178 Xiawan Rd, 珠海市, 广东省",
    phone: "010 5820 3333",
    status: 2,
  },
  {
    key: "2",
    name: "揭腊梅",
    age: 42,
    address: "Cuiyuan Rd, 苏州市, 江苏省",
    phone: "010 6569 1855",
    status: 1,
  },
  {
    key: "3",
    name: "路祥",
    age: 24,
    address: "Rua de Pequim, No. 199, Macau",
    phone: "0571 8796 9682",
    status: 2,
  },
  {
    key: "4",
    name: "许婷婷",
    age: 22,
    address: "33 Kuanxiangzi, 成都市, 四川省",
    phone: "010 6421 2702",
    status: 2,
  },
  {
    key: "5",
    name: "叶淑珍",
    age: 33,
    address: "27 Shuangfeng Rd, 武侯区, 四川省",
    phone: "028 8701 2552",
    status: 2,
  },
  {
    key: "6",
    name: "桑红",
    age: 33,
    address: "29 Qingnian Rd, 奎文区, 山东省",
    phone: "0536 832 2480",
    status: 2,
  },
  {
    key: "7",
    name: "晋志强",
    age: 20,
    address: "Yingbin South Rd, 珠海市, 广东省",
    phone: "0756 812 2901",
    status: 1,
  },
];
