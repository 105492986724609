export const capitalised = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export function getQueryParam(queryString?: string) {
  const urlSearchParams = new URLSearchParams(queryString);
  const queryObj = Object.fromEntries(urlSearchParams.entries());
  return queryObj;
}

export const formatDollar = (amount: any, withDollarSign?: false) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat(
    "en-US",
    withDollarSign
      ? {
          style: "currency",
          currency: "USD",
        }
      : {}
  );
  return formatter.format(amount);
};
