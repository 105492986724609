import { TableTransactions } from "src/components/table-transactions";
import { Container } from "../components";
export const Transactions = (props: any) => {
  return (
    <Container showNav={true} showMenu={true}>
      <div className="flex flex-col p-[30px] pt-[100px] flex-1">
        {/* Table */}
        <TableTransactions />
      </div>
    </Container>
  );
};
