import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useEffect, useState } from "react";
import { LoadingIcon } from "./loading-icon";
export const ModalNameCheck = (props: any) => {
  const { isModalOpen, setIsModalOpen } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [loading]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        <h2>名稱審核</h2>

        <div className="flex flex-col justify-center items-center py-[20px] flex-1">
          {loading && <LoadingIcon />}
          {!loading && <p>名字已通過審核 ✅，此用戶為正常用戶。</p>}
        </div>
      </div>
    </Modal>
  );
};
