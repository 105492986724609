import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useEffect, useState } from "react";
import { LoadingIcon } from "./loading-icon";
export const ModalKycFiles = (props: any) => {
  const { isModalOpen, setIsModalOpen } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) return;
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [loading]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        <h2>已提交文檔</h2>

        {loading && (
          <div className="flex flex-col justify-center items-center py-[20px] flex-1">
            <LoadingIcon />
          </div>
        )}

        {!loading && (
          <div className="flex flex-row justify-start items-start py-[20px] flex-1">
            <>
              <div>
                <p>住址證明</p>
                <img
                  src="https://pbs.twimg.com/media/DEY_R4OUQAAdmY6.jpg"
                  className="w-[300px] object-cover mt-[15px]"
                  alt="address-proof"
                />
              </div>

              <div className="ml-[20px]">
                <p>自拍證明</p>
                <img
                  src="https://backycheck.com.au/assets/img/others/correct-image.jpg"
                  className="w-[300px] object-cover mt-[15px]"
                  alt="selfie-proof"
                />
              </div>
            </>
          </div>
        )}
      </div>
    </Modal>
  );
};
