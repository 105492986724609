import DonutChart from "react-donut-chart";

const passedKycUser = 10;
const failedKycUser = 2;
const passPerc = (passedKycUser / (passedKycUser + failedKycUser)) * 100;
const failPerc = (failedKycUser / (passedKycUser + failedKycUser)) * 100;

export const DashboardKycStat = (props: any) => {
  return (
    <div className="w-[750px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3>最新 KYC 數據</h3>

      <div className="flex flex-col mt-[20px]">
        <p className={$label}>新增用戶</p>
        <h2 className="mt-[10px]">10</h2>
      </div>

      {/* KYC Stat */}
      <div className="mt-[20px]">
        <p className={$label}>KYC 最新通過數據</p>
        <div className="flex flex-flex mt-[10px] justify-center items-center">
          <p className="pr-[10px]">成功通過</p>
          <p className="pr-[10px] text-[#389E0D]">{passPerc.toFixed(0)}%</p>

          <div className="relative flex-1 border-[1px] w-full h-[15px] rounded-[10px]">
            <div
              className="absolute top-0 left-0 bg-gradient-to-r from-[#1FA2FF] to-[#84FFB7] h-[13px] rounded-tl-[10px] rounded-bl-[10px]"
              style={{
                width: `${passPerc}%`,
              }}
            />
          </div>

          <p className="pl-[10px] text-[#F5423E]">{failPerc.toFixed(0)}%</p>
          <p className="pl-[10px]">通過失敗</p>
        </div>
      </div>

      {/* Donut Chart */}
      <div className="mt-[20px] bg-gradient-to-b from-[#f1f1f1] to-[#fff] p-[20px] rounded-[10px]">
        <p className={$label}>KYC 本月數據</p>
        <div className="mt-[10px]">
          <DonutChart
            innerRadius={0.65}
            strokeColor={"#fff"}
            colors={["#389E0D", "#F5423E", "#1FA2FF"]}
            data={[
              {
                label: "通過 KYC 成功",
                value: 5,
              },
              {
                label: "通過 KYC 失敗",
                value: 2,
              },
              {
                label: "等待 KYC 審核",
                value: 2,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const $label = "text-[10px] text-[#333] mt-[10px]";
