import { Popconfirm, Space, Table, Tag } from "antd";
import { useState } from "react";
import { dataSource } from "src/config/sample-kyc";
import { ModalNameCheck } from "./modal-name-check";
import { ModalKycFiles } from "./modal-kyc-files";

export const TableKyc = (props: any) => {
  const [openNameCheckModal, setOpenNameCheckModal] = useState(false);
  const [openKycFileModal, setOpenKycFileModal] = useState(false);
  const columns = [
    {
      title: "名稱",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "年齡",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "地址",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "電話號碼",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "審核狀態",
      key: "tags",
      dataIndex: "status",
      render: (tag: number) => {
        let color = "geekblue";
        let txt = "審核中";
        if (tag === 1) {
          color = "red";
          txt = "已拒絕";
        }
        if (tag === 2) {
          color = "green";
          txt = "已認證";
        }
        return (
          <Tag color={color} key={tag}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "行動",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <p
            className={`${$action} text-[#1FA2FF]`}
            onClick={() => setOpenNameCheckModal(true)}
          >
            一鍵審核名稱
          </p>
          <p
            className={`${$action} text-[#1FA2FF]`}
            onClick={() => setOpenKycFileModal(true)}
          >
            查看提交文檔
          </p>
          <Popconfirm title="確定通過審核嗎？" okText="確定" cancelText="取消">
            <p className={`${$action} text-[#389E0D]`}>批核</p>
          </Popconfirm>

          <Popconfirm title="確定拒絕審核嗎？" okText="確定" cancelText="取消">
            <p className={`${$action} text-[#F5423E]`}>拒絕</p>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3>KYC 審核</h3>
      <div className="mt-[20px]">
        <Table dataSource={dataSource} columns={columns} />
      </div>

      {/* Name Check Modal */}
      <ModalNameCheck
        isModalOpen={openNameCheckModal}
        setIsModalOpen={setOpenNameCheckModal}
      />

      {/* KYC File */}
      <ModalKycFiles
        isModalOpen={openKycFileModal}
        setIsModalOpen={setOpenKycFileModal}
      />
    </div>
  );
};

const $action = "cursor-pointer";
