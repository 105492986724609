import {
  DashboardOutlined,
  SaveOutlined,
  SecurityScanOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { history } from "src/stores";

const MENU_LIST = [
  {
    name: "儀表板",
    icon: (
      <DashboardOutlined
        style={{
          color: "#fff",
        }}
      />
    ),
    page: "/dashboard",
  },
  {
    name: "KYC 審核",
    icon: (
      <SecurityScanOutlined
        style={{
          color: "#fff",
        }}
      />
    ),
    page: "/kyc",
  },
  {
    name: "流水紀錄",
    icon: (
      <UnorderedListOutlined
        style={{
          color: "#fff",
        }}
      />
    ),
    page: "/transactions",
  },
  {
    name: "指令紀錄",
    icon: (
      <SaveOutlined
        style={{
          color: "#fff",
        }}
      />
    ),
    page: null,
  },
  {
    name: "帳戶中心",
    icon: (
      <UserOutlined
        style={{
          color: "#fff",
        }}
      />
    ),
    page: null,
  },
];

export const SideMenu = (props: any) => {
  return (
    <div className="bg-[#000]/80 w-[350px] flex flex-col min-h-screen justify-start items-start py-[30px] px-[30px] relative">
      {/* Logo */}
      <h1
        className="text-white font-[900] text-[48px] cursor-pointer"
        onClick={() => history.push("/dashboard")}
      >
        633
      </h1>

      {/* Label */}
      <div className="mt-[20px]">
        <p className={$label}>主菜單</p>
      </div>

      {/* Menu */}
      <div className="flex-1">
        {MENU_LIST.map((item, i) => {
          return (
            <div
              key={i}
              className="mt-[10px] cursor-pointer flex flex-row items-center"
            >
              {item.icon}
              <p
                className={$menu}
                onClick={() => item?.page && history.push(item?.page)}
              >
                {item.name} {!item?.page && "（即將推出）"}
              </p>
            </div>
          );
        })}
      </div>

      {/* Logout */}
      <p
        className="text-[#F5423E] cursor-pointer fixed bottom-[30px] left-[30px]"
        onClick={() => history.push("/")}
      >
        登出
      </p>
    </div>
  );
};

const $label = "text-[10px] text-[#fff] font-[400]";
const $menu = "text-[12px] text-[#fff] font-[500] cursor-pointer ml-[10px]";
