export const dataSource = [
  {
    amount: 500,
    risk: 22,
  },
  {
    amount: 120000,
    risk: 71,
    remark: "有可疑交易傾向",
  },
  {
    amount: 500,
    status: 1,
  },
  {
    amount: 100,
    risk: 35,
  },
  {
    amount: 1,
    risk: 1,
  },
];
