import { Space, Table, Tag } from "antd";
import { useState } from "react";
import { makeid } from "src/utils";
import moment from "moment";
import { dataSource } from "src/config/sample-transaction";
import { ModalTransactionFiles } from "./modal-transaction-files";

export const TableTransactions = (props: any) => {
  const [openTransactionFileModal, setOpenTransactionFileModal] =
    useState(false);
  const columns = [
    {
      title: "幣種",
      dataIndex: "currency",
      key: "currency",
      render: (_: any) => {
        return <img src="/assets/hkd.png" alt="currency" />;
      },
    },
    {
      title: "金額",
      dataIndex: "amount",
      key: "amount",
      render: (amount: any) => {
        return <p>{amount} HKD</p>;
      },
    },
    {
      title: "類別",
      dataIndex: "type",
      key: "type",
      render: (_: any) => {
        return <p>轉入</p>;
      },
    },
    {
      title: "交易 tx",
      dataIndex: "tx",
      key: "tx",
      render: (_: any) => {
        return <p>{makeid(32)}</p>;
      },
    },
    {
      title: "交易時間",
      dataIndex: "created_at",
      key: "created_at",
      render: (_: any) => {
        return <p>{moment().format("DD MMM YYYY")}</p>;
      },
    },
    {
      title: "風險",
      dataIndex: "risk",
      key: "risk",
      render: (risk: any, i: any) => {
        let color = "green";
        let txt = "安全";
        if (risk >= 30) {
          color = "yellow";
          txt = "中等風險";
        }
        if (risk >= 70) {
          color = "red";
          txt = "高風險";
        }
        if (!risk) {
          return null;
        }
        return (
          <>
            <Tag color={color} key={i}>
              {txt}: {risk}
            </Tag>
            {risk >= 70 && (
              <p
                className={`${$action} text-[#1FA2FF] mt-[5px]`}
                onClick={() => null}
              >
                通過交易
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "交易狀態",
      key: "tags",
      dataIndex: "status",
      render: (tag: number) => {
        let color = "green";
        let txt = "成功";
        if (tag === 1) {
          color = "red";
          txt = "失敗";
        }
        return (
          <Tag color={color} key={tag}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "備注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "行動",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <p className={`${$action} text-[#1FA2FF]`} onClick={() => null}>
            通知管理員
          </p>
          <p
            className={`${$action} text-[#1FA2FF]`}
            onClick={() => setOpenTransactionFileModal(true)}
          >
            上傳或查看交易證明
          </p>
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3>交易紀錄</h3>
      <div className="mt-[20px]">
        <Table dataSource={dataSource} columns={columns} />
      </div>

      {/* KYC File */}
      <ModalTransactionFiles
        isModalOpen={openTransactionFileModal}
        setIsModalOpen={setOpenTransactionFileModal}
      />
    </div>
  );
};

const $action = "cursor-pointer";
