import React from "react";
import { Provider } from "mobx-react";
import Routers from "./router";
import { stores, StoresContext } from "./stores";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig } from "wagmi";
import { ethereumClient, projectId, wagmiConfig } from "./config";

function App() {
  return (
    <>
      <Provider {...stores}>
        <StoresContext.Provider value={stores}>
          <WagmiConfig config={wagmiConfig}>
            <Routers />
          </WagmiConfig>
        </StoresContext.Provider>
      </Provider>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode={"light"}
        themeVariables={{
          "--w3m-z-index": "9999",
        }}
      />
    </>
  );
}

export default App;
