export const Header = (props: any) => {
  return (
    <div className="fixed bg-transparent w-full px-[30px] py-[30px] flex flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1 "></div>

      {/* Menu */}
      <div className="flex flex-row justify-center items-center">
        <button className={`${$btnStyle} mr-[20px]`}>繁體中文</button>
        <button className={`${$btnStyle}`}>Andy Chen</button>
      </div>
    </div>
  );
};

const $btnStyle = "border-[1px] text-[#000] !py-[2px] h-[40px]";
