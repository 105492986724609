import { useState } from "react";
import { Container } from "../components";
import { history } from "src/stores";
export const Home = (props: any) => {
  const [stage, setStage] = useState("email"); // email -> password
  return (
    <Container showNav={false}>
      <div className="flex flex-row">
        {/* Left Col - Banner, change lang & desc */}
        <div
          className="w-[50vw] min-h-screen h-full relative p-[5%] overflow-hidden flex flex-col"
          style={{
            backgroundImage: `url("/assets/bg.jpg")`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          {/* Overlay */}
          <div
            className={`bg-[#000]/50 absolute left-0 top-0 w-full h-full z-[10]`}
          />

          {/* Logo & Lang Selection */}
          <div className="flex flex-row relative z-[15] flex-1">
            <h1 className="text-white font-[900] flex-1 text-[48px]">633</h1>
            <button className="border-[1px] border-white !py-[2px] h-[40px]">
              繁體中文
            </button>
          </div>

          {/* Title & Description  */}
          <div className="flex flex-col relative z-[15] w-full">
            <h2 className="text-white text-[24px]">兌換審查系統</h2>
            <p className="text-white text-[16px] mt-[30px]">
              兌換審查系統是一個關鍵的金融工具，旨在確保金融交易的安全性和合規性。該系統涵蓋了兩個主要功能：首先，用戶提交的KYC（了解您的客戶）表格，這些表格包含個人資訊和身份證明，用於驗證和確認客戶的身份。其次，它包括交易監控，系統將跟蹤和分析所有交易活動，以檢測可能的不正當行為，如洗錢等。通過建立規則和模型，系統可以自動檢測潛在的風險交易並生成警報，以通知相關人員進一步調查。兌換審查系統的目標是確保金融機構遵守相關法律法規，防止金融犯罪，並保護金融體系的安全性。這有助於確保金融交易的合法性，保護客戶的資金，並確保金融系統的穩定運作。
            </p>
          </div>
        </div>
        {/* Right Col - Login */}
        <div className="w-[50vw] h-screen bg-white flex flex-col items-start justify-center p-[5%]">
          <div className="w-[450px] mx-auto text-left">
            <h2>登入</h2>
            <p className="text-[10px] text-[#333] mt-[10px]">
              登入到您的帳戶。
            </p>

            {stage === "email" && (
              <>
                <div className="mt-[30px]">
                  <p>電子郵件</p>
                  <input
                    placeholder="example@gmail.com"
                    className={$inputStyle}
                  />
                </div>

                <div className="mt-[30px]">
                  <button
                    className={$continueBtn}
                    onClick={() => setStage("password")}
                  >
                    繼續
                  </button>
                </div>
              </>
            )}

            {stage === "password" && (
              <>
                <div className="mt-[30px]">
                  <p>密碼</p>
                  <input className={$inputStyle} type="password" />
                </div>

                <div className="mt-[30px]">
                  <button
                    className={$continueBtn}
                    onClick={() => history.push("/dashboard")}
                  >
                    繼續
                  </button>
                </div>

                <div className="mt-[10px]">
                  <button
                    className={$cancelBtn}
                    onClick={() => setStage("email")}
                  >
                    返回
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

const $continueBtn = "bg-gradient-to-r from-[#1FA2FF] to-[#84FFB7] w-full";
const $cancelBtn = "bg-white border-[1px] text-black w-full";
const $inputStyle =
  "border-[1px] px-[10px] py-[3px] mt-[5px] rounded-[5px] w-full";
