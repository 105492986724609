import { DashboardKycStat } from "src/components/dashboard-kyc-stat";
import { Container } from "../components";
import { DashboardTransactionStat } from "src/components/dashboard-transaction-stat";
export const Dashboard = (props: any) => {
  return (
    <Container showNav={true} showMenu={true}>
      <div className="flex flex-col p-[30px] pt-[100px]">
        <h2>你好 Andy Chen，</h2>
        <p className="text-[10px] text-[#333] mt-[10px]">
          歡迎回到 633 兌換審核平台！
        </p>

        {/* Dashboard Things */}
        <div className="mt-[20px] flex flex-row flex-wrap">
          <DashboardKycStat />

          <div className="ml-[20px]">
            <DashboardTransactionStat />
          </div>
        </div>
      </div>
    </Container>
  );
};
