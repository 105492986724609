import { Container } from "../components";
import { TableKyc } from "src/components/table-kyc";
export const Kyc = (props: any) => {
  return (
    <Container showNav={true} showMenu={true}>
      <div className="flex flex-col p-[30px] pt-[100px] flex-1">
        {/* Table */}
        <TableKyc />
      </div>
    </Container>
  );
};
