import moment from "moment";
import { history } from "src/stores";
import { makeid } from "src/utils";

const TRANSACTION_LIST = [
  {
    amount: 500,
  },
  {
    amount: 1200,
  },
  {
    amount: 500,
    status: "failed",
  },
  {
    amount: 100,
  },
  {
    amount: 1,
  },
];

export const DashboardTransactionStat = (props: any) => {
  return (
    <div className="w-[550px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <div className="flex flex-row">
        <h3 className="flex-1">最近流水紀錄</h3>
        <p
          className="text-[#1FA2FF] cursor-pointer"
          onClick={() => history.push("/transactions")}
        >
          查看全部交易
        </p>
      </div>
      <div className="mt-[20px] flex flex-row">
        <div>
          <button className={$statusBtn}>重新整理</button>
        </div>
      </div>

      {/* Transactions */}
      <div className="mt-[20px]">
        {TRANSACTION_LIST.map((item, i) => {
          const { status = "succeed", amount } = item;
          const txtColor =
            status === "succeed" ? "text-[#389E0D]" : "text-[#F5423E]";
          return (
            <div className="mt-[20px] flex flex-row items-center" key={i}>
              <img src="/assets/hkd.png" alt="currency" />
              <div className="flex flex-col ml-[20px] flex-1">
                <p className={$label}>{makeid(12)}</p>
                <p>轉入</p>
                <p className={`${txtColor}`}>
                  ． {status === "succeed" ? "成功" : "失敗"}
                </p>
              </div>
              <div className="flex flex-col ml-[20px] flex-1 text-right">
                <p className={$label}>{moment().format("DD MMM YYYY")}</p>
                <p className={`${txtColor}`}>{amount} HKD</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const $label = "text-[10px] text-[#666]";
const $statusBtn =
  "bg-white border-[1px] text-[#666] w-full h-[30px] py-[2px] rounded-[5px]";
